<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
    ></WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/models/penguin";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name"],
    rightFields: ["ice", "friends"],
  }),

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>